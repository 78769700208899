import React, { useState, useEffect } from "react";
import {
  ChartBarSquareIcon,
  UserCircleIcon,
  CalendarDaysIcon,
  CalendarIcon,
  TrophyIcon,
  ArrowLeftStartOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
  BanknotesIcon,
  PresentationChartLineIcon,
  HomeIcon,
  ClockIcon,
  CreditCardIcon,
  TagIcon,
  LinkIcon
} from "@heroicons/react/24/solid";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { Dropdown, Menu } from "antd";
import { CaretDownOutlined, TagOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "../../styles/Navbar.css";

const Navbar = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const storedToken = localStorage.getItem("token");

  const handleLogout = async () => {
    try {
      await axios.post(`${API_BASE_URL}/logout`, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      });
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const Menus = [
    { title: "Dashboard", src: "ChartBarSquareIcon", route: "/dashboard" },
    { title: "Appointment", src: "CalendarIcon", route: "/appointment" },
    { title: "Sales", src: "CreditCardIcon", route: "/sale" },
    { title: "Attendance", src: "CalendarDaysIcon", route: "/attendance" },
    { title: "Commissions", src: "BanknotesIcon", route: "/commissions" },
    // { title: "Rewards", src: "TrophyIcon", route: "/rewards" },
    // { title: "Rewards Catalog", src: "ShoppingBagIcon", route: "/rewardscatalog" },
    // { title: "Points", src: "TagIcon", route: "/points" },
    // { title: "Leaderboard", src: "PresentationChartLineIcon", route: "/Leaderboard"},
    { title: "Prestige Academy", src: "HomeIcon", route: "/prestigeacademy" },
    { title: "Download Forms", src: "PencilIcon", route: "/download" },
    { title: "Quick Links", src: "LinkIcon", route: "/resources" },
    { title: "Profile", src: "UserCircleIcon", route: "/profile" },
    { title: "Logout", src: "ArrowLeftStartOnRectangleIcon", route: "/login" },
  ];

  function icons(src) {
    switch (src) {
      case "ChartBarSquareIcon":
        return <ChartBarSquareIcon className="h-6 w-6" />;

      case "UserCircleIcon":
        return <UserCircleIcon className="h-6 w-6" />;

      case "CalendarDaysIcon":
        return <CalendarDaysIcon className="h-6 w-6" />;

      case "CalendarIcon":
        return <CalendarIcon className="h-6 w-6" />;

      case "TrophyIcon":
        return <TrophyIcon className="h-6 w-6" />;

      case "ArrowRightStartOnRectangleIcon":
        return <ArrowRightStartOnRectangleIcon className="h-6 w-6" />;

      case "BanknotesIcon":
        return <BanknotesIcon className="h-6 w-6" />;

      case "PresentationChartLineIcon":
        return <PresentationChartLineIcon className="h-6 w-6" />;

      case "HomeIcon":
        return <HomeIcon className="h-6 w-6" />;

      case "ClockIcon":
        return <ClockIcon className="h-6 w-6" />;

      case "ArrowLeftStartOnRectangleIcon":
        return <ArrowLeftStartOnRectangleIcon className="h-6 w-6" />;

      case "CreditCardIcon":
        return <CreditCardIcon className="h-6 w-6" />;

      case "ShoppingBagIcon":
        return <CreditCardIcon className="h-6 w-6" />;

      case "TagIcon":
        return <TagIcon className="h-6 w-6" />;

      case "PencilIcon":
        return <TagIcon className="h-6 w-6" />;
      
        case "LinkIcon":
          return <LinkIcon className="h-6 w-6" />;
        
      default:
        return null;
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <a href="/restday">Rest Day</a>
      </Menu.Item>
      {/* <Menu.Item key="4">
        <a href="/leave">Leave Form</a>
      </Menu.Item> */}
      {/* <Menu.Item key="3">
        <a href="/officialbusiness">Official Business</a>
      </Menu.Item> */}
      <Menu.Item key="0">
        <a href="/attendance">View Attendance</a>
      </Menu.Item>
      {/* <Menu.Item key="2">
        <a href="/scheduleadjustment">Schedule Adjustment</a>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <div id="navbar">
      <div
        className={` ${
          open ? "w-72 h-auto" : "w-20 h-auto"
        } h-screen p-5  pt-8 relative duration-300`}
        style={{ zIndex: 0 }}
      >
        <ArrowLeftCircleIcon
          className={`fill-white  rounded-full absolute cursor-pointer -right-4 top-9 w-9 ${
            !open && "rotate-180"
          }`}
          onClick={() => setOpen(!open)}
        />
        <div className="flex gap-x-4 items-center justify-center">
          <img
            src={require("../../assets/logo.png")}
            className={`cursor-pointer duration-500 ${
              open && "rotate-[360deg] w-[65%] h-[65%]"
            }`}
          />
        </div>
        <ul className="pt-6">
          {Menus.map((Menu, index) => (
            <li
              key={index}
              className={`flex  rounded-md p-2 cursor-pointer transition-all hover:bg-light-white text-sm items-center gap-x-4 
                            ${Menu.gap ? "mt-9" : "mt-2"} ${
                index === 0 && "bg-light-white"
              } `}
            >
              {icons(Menu.src)}
              <span className={`${!open && "hidden"} origin-left duration-200`}>
                {Menu.title === "Attendance" ? (
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomLeft"
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      {Menu.title} <CaretDownOutlined className="ml-2" />
                    </a>
                  </Dropdown>
                ) : Menu.title === "Logout" ? (
                  <div className="" onClick={handleLogout}>
                    {Menu.title}
                  </div>
                ) : (
                  <a href={Menu.route}>{Menu.title}</a>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
