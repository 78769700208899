import React from 'react';
import { Link } from 'react-router-dom';
import { Button} from "antd";

const NotFound = () => {
  return (
    <div id="notFoundBody" >
    <div id="page" className="text-center" >
       
            <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">404</h1>
            <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Something's missing.</p>
            <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
            <Link to="/dashboard"><Button className="inline-flex bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">
          Back to Homepage
        </Button>
        </Link>
    </div>
    </div>
  );
};

export default NotFound;
