import React, { useEffect, useState } from 'react';
import { Button, Table, ConfigProvider} from 'antd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from "../components/common";
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx'; 
import axios from 'axios';

import "../index.css";

const QuickLinksPage = () => {
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const API_BASE_URL_API = process.env.REACT_APP_API_BASE_URL_API;

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/resources`);
        setAppointments(response.data.quickLinks);
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
    };

    fetchForms();
  }, []);

  

  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      width: '15%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '15%',
    },
    
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* Use a link to trigger the download */}
          <a href={`${record.links}`} target="_blank" rel="noopener noreferrer">
            Link
          </a>
        </div>
      ),
    },
  ];

  return (
    <div id='body'>
      <Header pageName="Quick Links"/>
      <div id="page">
        <Table
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={appointments}
          columns={columns}
          className='w-100 h-100'
        />
        <ToastContainer/>
      </div>
    </div>
  );
};

export default QuickLinksPage;
