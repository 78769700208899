import React from 'react';
import { Pie } from '@ant-design/plots';
import '../../PieChart.css';

const formatNumber = (number) => {
  return new Intl.NumberFormat().format(number);
};

const PieChart = ({ data }) => {
  const config = {
    data: data,
    angleField: 'total_production',
    colorField: 'name',
    radius: 0.8,
    label: {
      type: 'outer',
      content: ({ name, total_production }) => `${name}\n${formatNumber(total_production)}`,
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  
 
  return <Pie {...config} />;
};


export default PieChart;
